.overlayNewProject {
  position: absolute;
  top: 0;
  left: 25%;
  width: 75%;
  height: 100%;
  background: rgba(0, 0, 0, 40%);
  z-index: 2;
}
@media only screen and (max-width: 767px) {
.overlayNewProject {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 40%);
  z-index: 2;
}
}