.link {
    color:#fff;
    text-decoration: none;
    transition: 0.5s;
}
.link:hover {
    color:#D5AB4D;
    text-decoration: underline;
}

@media only screen and (max-width: 767px) {

    .link {
        color:#FABB25;
        text-decoration: none;
        transition: 0.5s;
    }
    .link:hover {
        color:#faba2574;
        text-decoration: underline;
    }
}

.nav-logo {
    height: 7rem;
}